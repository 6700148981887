import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const reportType  = [{id: 1, name:"Date"},{id: 2, name:"Month"},{id: 3, name:"Year"}];

const GrossSalesReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [type, setType] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("gross");
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
            setBranchSearch(
            branchForSearch.filter(
                (branch) => branch.id === authUserInfo.details.branch_id
            )
            );
        } else {
            setBranchSearch(branchForSearch);
        }
        
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [generalSettings]);

    const handleType = (type) =>{
      setType(type)
    }

    const handleBranch=(branch)=>{
     setBranch(branch)
    }
  
    const handleOption  = (e) =>{
      setOption(e.target.value)
    }

    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null &&
        type
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-gross-sales-reports";
        const formData = new FormData();
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);
        formData.append("branch_id", branch?.id ? branch?.id : "");
        formData.append("report_type", type ? type?.name : "");
        formData.append("type", option);
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setGeneratedReport(true);
            setReportData(res.data)
            setDataPaginating(false);
            componentRef.current.handleDownload();
          
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and type"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    const checkDateValue = (item, key) => {
      let text; // Define text within the function's scope
      switch (key) {
        case "Date":
          text = moment(item).format("DD-MMM-YYYY");
          break;
        case "Month":
          text = moment(item).format("MMM-YYYY");
          break;
        default:
          text = item;   
      }
      return text;
    }

  
  return (
    <>
    <Helmet>
      <title>{_t(t("Gross Sales Report"))}</title>
    </Helmet>
    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Gross Sales Report"
      sheet="sheet 1"
      buttonText="Gross Detail"
      ref={componentRef}
    />   
 

     {/* Excel Report*/}
     <div className="d-none">
     <div>           
       <div className="fk-print">
         <div className="container">
           <div className="row">
             <div className="col-12">
               {reportData !== null &&
                reportData !== undefined &&
                generatedReport === true && (
                    <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                    <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                     <table
                        className="table table-bordered table-hover min-table-height mt-3"
                        id="table-to-xls"
                     >
                        <div className="page-break"></div>
                        <thead className="align-middle">
                        <tr style={{ border: "0px" }}>
                            <th colspan={reportData ? Object.keys(reportData[0])?.length : "10"} style={{ border: "0px" }}>
                            <div className="row">
                                <div className="col-12">
                                <h3 className="text-center mb-0">
                                    {siteName}
                                </h3>
                                <h3 className="text-center mt-0">
                                    Gross Sales Report
                                </h3>
                                <p className="text-center">
                                    FROM&nbsp;
                                    <Moment format="LL">
                                    {startDate}
                                    </Moment>
                                    &nbsp;TO&nbsp;
                                    <Moment format="LL">{endDate}</Moment>
                                </p>
                                </div>
                            </div>
                            </th>
                        </tr>
                        <tr>
                            {
                                reportData && Object.keys(reportData[0]).map(item => (
                                    <th
                                    scope="col"
                                    >
                                    {_t(t(item))}
                                    </th>
                                ))
                            }
                                                            
                        </tr>
                        </thead>
                        <tbody className="align-middle"> 
                        {
                            reportData && reportData.length > 0 && reportData.map((item)=>{
                               return (
                                <tr>
                                   {Object.keys(item).map((key) => {
                                      const value = checkDateValue(item[key],key)
                                      return (
                                        <td>
                                        {value}
                                        </td>
                                      )
                                   })}
                                </tr>
                               )
                            })
                        }                               
                        </tbody>

                        <tfoot style={{ border: "0px" }}>
                        <tr style={{ border: "0px" }}>
                            <td
                            colspan="7"
                            className="text-center"
                            style={{ border: "0px" }}
                            >
                            <h5 className="mt-3">
                                Powered by indolj.pk
                            </h5>
                            </td>
                        </tr>
                        </tfoot>
                     </table>
                    </div>
                    </div>
                 )}
             </div>
           </div>
         </div>
       </div>
     </div>
     </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                  <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                 
                  {/* spin loading ends */}

                  {/* Loading effect {loading === true ? (
                    tableLoading()
                  ) : ( */}
                  
                    <div key="smtp-form">
                      <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="row t-mb-15 mb-md-0" >
                          <ul className="t-list fk-breadcrumb col-6">
                            <li className="fk-breadcrumb__list">
                              <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Gross Sales Report"))}
                              </span>
                            </li>
                          </ul>
                          <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="gross"
                                  name="gross"
                                  value="gross"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "gross" ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="gross"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  {_t(t("Gross"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="net_sales"
                                  name="net_sales"
                                  value="net_sales"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "net_sales"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="net_sales"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  {_t(t("Net Sales"))}
                                </label>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-2">
                          <Select
                            options={reportType ? reportType : []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={type}
                            isClearable
                            classNamePrefix="select"
                            className="table-text"
                            onChange={handleType}                                
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select type")) + ".."}
                          />
                        </div>
                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control t-mb-15 mb-md-0"
                            placeholderText={_t(t("From date"))}
                            showMonthYearPicker={type?.name === "Month"}
                            showYearPicker={type?.name === "Year"}
                          />
                        </div>

                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control t-mb-15 mb-md-0"
                            placeholderText={_t(t("To date"))}
                            showMonthYearPicker={type?.name === "Month"}
                            showYearPicker={type?.name === "Year"}
                          />
                        </div>                                                                                 

                        <div className="col-3">
                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={branch}
                            isClearable
                            classNamePrefix="select"
                            className="table-text"
                            onChange={handleBranch}                                
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a branch")) + ".."}
                          />
                        </div>

                        <div className="col-1">
                       
                        </div> 

                        <div className="col-2 t-mb-15 mb-md-0 text-right">
                          <button
                          className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                          onClick={(e) => getBranchWiseSelected()}
                          >
                           GROSS REPORT
                          </button>
                        </div>  
                      </div>                   
                      
                    </div>                                          
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>

  </>
  )
}

export default GrossSalesReport;

